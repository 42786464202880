<template>
  <div class="title-box">
    <el-row type="flex" justify="center" align="middle">
      <el-col :span="4">
        <el-image :src="proxyLogo" fit="contain" class="img-logo" />
      </el-col>
      <el-col :span="16">
        <el-input placeholder="请输入搜索内容" v-model="$data.dataValue" class="search-border" @keyup.enter.native="onSearchClick">
          <el-select slot="prepend" v-model="$data.dataType" placeholder="请选择" style="width: 100px;">
            <el-option label="查资料" value="1"></el-option>
            <el-option label="查院校" value="2"></el-option>
            <el-option label="查专业" value="3"></el-option>
            <el-option label="查资讯" value="4"></el-option>
          </el-select>
          <el-button slot="append" type="primary" class="search-button" @click="onSearchClick">搜索</el-button>
        </el-input>
      </el-col>
      <el-col :span="4">
        <el-button v-if="!this.$store.getters.isLogin" type="primary" size="small" @click="onLoginClick">登录/注册</el-button>
        <el-button v-else type="primary" size="small" @click="onLogoutClick">注销</el-button>
        <i class="iconfont icon-caidan system-menu" @click="onProxySystem"></i>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Tools from "@/tool";
import Config from "@/api/service";
export default {
  name: "TitleBar",
  components: {
  },
  props: {
    type: {
      type: String,
      default: '1',
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dataType: '1',
      dataValue: null,
    };
  },
  computed: {
    proxyLogo: function () {
      return `${Config.proxy.image}?ProxyCode=${this.$store.state.proxyCode}&ImageIdentity=0`;
    }
  },
  watch: {
    '$props.type': {
      deep: true,
      immediate: true,
      handler(val) {
        this.$data.dataType = val;
      }
    },
    '$props.value': {
      deep: true,
      immediate: true,
      handler(val) {
        this.$data.dataValue = val;
      }
    },
    '$data.dataType': {
      deep: true,
      immediate: true,
      handler(val) {
        this.$emit('update:type', val);
      }
    },
    '$data.dataValue': {
      deep: true,
      immediate: true,
      handler(val) {
        this.$emit('update:value', val);
      }
    },
  },
  methods: {
    onSearchClick() {
      this.$emit("search-click");
    },
    onLoginClick() {
      this.$emit("login-click");
    },
    onLogoutClick() {
      this.$emit("logout-click");
    },
    onProxySystem() {
      this.$emit("proxy-click");
    }
  },
};
</script>

<style scoped>
.el-row {
  height: 100%;
}

.el-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-box {
  margin-top: 30px;
  margin-bottom: 10px;
}

.img-logo {
  height: 40px;
  width: 180px;
  margin-left: 10px;
}

.search-border {
  width: 80%;
  height: 38px;
}

.search-input {
  width: 100%;
  height: 100%;
  margin: 2px;
  border-width: 0px;
  font-size: 14px;
}

.system-menu {
  cursor: pointer;
  margin-left: 10px;
  font-size: 20px;
  line-height: 20px;
}

::v-deep .el-input-group__append {
  width: 60px;
  border-width: 0px;
  border-radius: 0px 5px 5px 0px;
  background-color: #FE5E03;
  color: white;
}

::v-deep .el-input-group__append:hover {
  background-color: rgb(252, 134, 65);
  color: white;
}

::v-deep .el-input-group__append:active {
  background-color: #FE5E03;
  color: white;
}
</style>
