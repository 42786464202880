<template>
  <div class="home-box">
    <div class="home-container">
      <title-bar :type.sync="type" :value.sync="keyword" @login-click="onLoginClick" @logout-click="onLogoutClick" @proxy-click="onProxyClick" @search-click="onSearchClick"></title-bar>
      <el-menu mode="horizontal" text-color="black" active-text-color="#FE5E03" :router='true' :default-active="activeMenu">
        <!-- <el-menu-item index="01" :route="{ name: 'Home', params: { proxyCode: $store.state.proxyCode } }">首页</el-menu-item> -->
        <el-menu-item index="02" :route="{ name: 'Document', params: { proxyCode: $store.state.proxyCode } }" v-if="menuDisplay('Document')">查资料</el-menu-item>
        <el-menu-item index="03" :route="{ name: 'School', params: { proxyCode: $store.state.proxyCode } }" v-if="menuDisplay('School')">查院校</el-menu-item>
        <el-menu-item index="04" :route="{ name: 'Major', params: { proxyCode: $store.state.proxyCode } }" v-if="menuDisplay('Major')">查专业</el-menu-item>
        <el-menu-item index="05" :route="{ name: 'Article', params: { proxyCode: $store.state.proxyCode } }" v-if="menuDisplay('Article')">查资讯</el-menu-item>
        <el-menu-item index="06" :route="{ name: 'AiSelection', params: { proxyCode: $store.state.proxyCode } }" v-if="menuDisplay('AiSelection')">AI择校</el-menu-item>
        <el-menu-item index="07" :route="{ name: 'SmartAdjust', params: { proxyCode: $store.state.proxyCode } }" v-if="menuDisplay('SmartAdjust')">智慧调剂</el-menu-item>
        <el-menu-item index="08" :route="{ name: 'GradeRank', params: { proxyCode: $store.state.proxyCode } }" v-if="menuDisplay('GradeRank')">估分排名</el-menu-item>
        <el-menu-item index="09" :route="{ name: 'NationalLine', params: { proxyCode: $store.state.proxyCode } }" v-if="menuDisplay('NationalLine')">国家线</el-menu-item>
        <el-menu-item index="10" :route="{ name: 'Adjust', params: { proxyCode: $store.state.proxyCode } }" v-if="menuDisplay('Adjust')">调剂公告</el-menu-item>
        <el-menu-item index="11" :route="{ name: 'Admit', params: { proxyCode: $store.state.proxyCode } }" v-if="menuDisplay('Admit')">报录数据</el-menu-item>
        <el-menu-item index="12" :route="{ name: 'Course', params: { proxyCode: $store.state.proxyCode } }" v-if="menuDisplay('Course')">视频网课</el-menu-item>
        <el-menu-item index="13" :route="{ name: 'League', params: { proxyCode: $store.state.proxyCode } }" v-if="menuDisplay('League')">加盟代理</el-menu-item>
        <el-menu-item index="14" :route="{ name: 'Contact', params: { proxyCode: $store.state.proxyCode } }">客服</el-menu-item>
        <el-menu-item index="15" :route="{ name: 'OrderList', params: { proxyCode: $store.state.proxyCode } }">订单</el-menu-item>
        <el-menu-item index="16" :route="{ name: 'Account', params: { proxyCode: $store.state.proxyCode } }">我的</el-menu-item>
      </el-menu>
      <keep-alive>
        <router-view :key="$route.fullPath" class="tab-view" v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view :key="$route.fullPath" class="tab-view" v-if="!$route.meta.keepAlive"></router-view>
      <footer-bar-for-handebook :contactInfo="proxyContactInfo"></footer-bar-for-handebook>
      <el-dialog center destroy-on-close :close-on-click-modal="false" :visible.sync="dialog" width="350px">
        <login-dialog @login="dialog = false"></login-dialog>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import TitleBar from "@/components/PC/TitleBar";
import FooterBarForHandebook from "@/components/PC/FooterBarForHandebook";
import Tools from "@/tool";
import Config from "@/api/service";

export default {
  name: "PCModule",
  components: {
    TitleBar,
    FooterBarForHandebook,
  },
  props: {
  },
  data() {
    return {
      dialog: false,
      type: '1',
      keyword: null,
      proxyContactInfo: null,
      proxyMenuPermissions: null,
    };
  },
  computed: {
    activeMenu: function () {
      const { meta, path } = this.$route;
      return meta.active;
    },
    menuDisplay() {
      return function (code) {
        if (this.proxyMenuPermissions && this.proxyMenuPermissions.indexOf(code) > -1) {
          return true;
        } else {
          return false;
        }
      }
    }
  },
  methods: {
    //获取路由参数
    getRoutParams() {
      if (this.$route.query.type)
        this.type = this.$route.query.type;
      if (this.$route.query.keyword)
        this.keyword = this.$route.query.keyword;
    },
    //获取代理商信息
    getProxyInfo() {
      var model = {
        ProxyCode: this.$store.state.proxyCode,
      };
      this.$axios
        .post(Config.proxy.info, model)
        .then((res) => {
          this.proxyContactInfo = res.data.Data.ContactInfo;
          this.proxyMenuPermissions = res.data.Data.MenuPermissions;
          //设置标题
          if (this.$route.name != "Course1" && this.$route.name != "Document1") {
            window.document.title = res.data.Data.WebsiteTitle;
          }
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //点击登录
    onLoginClick() {
      this.$loginDialog.show(true).then((res) => {
        this.$router.go(0);
      })
    },
    //点击登出
    onLogoutClick() {
      this.$axios.post(Config.user.logout, {})
        .then((res) => {
          this.$store.commit("setToken", null);
          this.$message.success("退出成功。");
          this.$router.go(0);
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //加盟商后台
    onProxyClick() {
      window.location.href = "https://www.handebook.com/proxy"
    },
    //点击搜索
    onSearchClick() {
      let params = {
        proxyCode: this.$store.state.proxyCode
      };
      let query = {
        type: this.type ? this.type : undefined,
        keyword: this.keyword ? this.keyword : undefined,
      };
      if (this.type == '1') {
        this.$router.push({ name: "Document", params: params, query: query });
      } else if (this.type == '2') {
        this.$router.push({ name: "School", params: params, query: query });
      } else if (this.type == '3') {
        this.$router.push({ name: "Major", params: params, query: query });
      } else if (this.type == '4') {
        this.$router.push({ name: "Article", params: params, query: query });
      }
    }
  },
  mounted() {
    this.getRoutParams();
    this.getProxyInfo();
  }
};
</script>
<style scoped>
::v-deep .el-dialog__header {
  padding: 10px;
}

.el-menu.el-menu--horizontal {
  display: flex;
  justify-content: space-between;
}

.el-menu-item {
  font-size: 18px;
  padding: 0px 12px;
}

.home-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-container {
  width: 1200px;
}

.tab-view {
  margin-top: 20px;
  margin-bottom: 100px;
  flex: 1;
}
</style>