<template>
  <div class="home-box">
    <van-sticky>
      <title-bar1 :type.sync="type" :value.sync="keyword" :day="distanceDays" @search="onSearch" @system="onSystem"></title-bar1>
    </van-sticky>
    <van-swipe class="home-swipe" :autoplay="3000" indicator-color="#FE5E03">
      <van-swipe-item>
        <van-image class="swipe-img" :src="proxySwipeImage(9)" fit="contain"></van-image>
      </van-swipe-item>
      <van-swipe-item>
        <van-image class="swipe-img" :src="proxySwipeImage(10)" fit="contain"></van-image>
      </van-swipe-item>
      <van-swipe-item>
        <van-image class="swipe-img" :src="proxySwipeImage(11)" fit="contain"></van-image>
      </van-swipe-item>
      <van-swipe-item>
        <van-image class="swipe-img" :src="proxySwipeImage(12)" fit="contain"></van-image>
      </van-swipe-item>
    </van-swipe>
    <van-swipe class="cell-swipe" autoplay="-" indicator-color="#FE5E03">
      <van-swipe-item v-for="i in getCellSwipeCount()">
        <van-grid :border="false" :column-num="getCellColumnCount()">
          <van-grid-item v-for="(item, index) in getCellPageImages(i)" :key="index" class="cell-grid">
            <div class="cell-item" @click="onCellClick(item)">
              <van-image :src="item.src" fit="contain" class="cell-img"></van-image>
              <div class="cell-txt">{{ item.txt }}</div>
            </div>
          </van-grid-item>
        </van-grid>
      </van-swipe-item>
    </van-swipe>
    <div class="school-hot">
      <img src="@/assets/images/pc/recommend.png" class="hot-img" />
      <span class="hot-title">热门院校推荐</span>
    </div>
    <school-item class="school-item" v-for="(school, index) in schools" :key="index" :title="school.name" :province="school.province" :teachtype="school.teachtype" :characteristic="school.characteristic" :img="school.src" @click="onItemClick(school)"></school-item>

    <div class="calendar-box">
      <div class="calendar-line1">
        <img class="line1-rect" src="@/assets/images/mt/rect.png" />
        <div class="line1-txt1">考研月历 </div>
        <div class="line1-txt2">左右滑动可以查看更多内容哦~</div>
      </div>
      <div class="calendar-line2">
        <div class="line2-txt">2024·Year</div>
      </div>
      <div class="calendar-line3">
        <img class="line3-img" src="@/assets/images/mt/calendar.png">
      </div>
    </div>

  </div>
</template>

<script>
import TitleBar1 from "@/components/MT/TitleBar1";
import SchoolItem from "@/components/MT/SchoolItem";
import Config from "@/api/service";

export default {
  name: "MTModule",
  components: {
    TitleBar1,
    SchoolItem,
  },
  props: {
  },
  data() {
    return {
      type: '1',
      keyword: null,
      menuPermissions: null,
      images: [{
        src: require('@/assets/images/mt/cell2.png'),
        txt: "查院校",
        num: 1,
        code: "School",
      }, {
        src: require('@/assets/images/mt/cell3.png'),
        txt: "查专业",
        num: 2,
        code: "Major",
      }, {
        src: require('@/assets/images/mt/cell1.png'),
        txt: "查资料",
        num: 3,
        code: "Document",
      }, {
        src: require('@/assets/images/mt/cell5.png'),
        txt: "AI择校",
        num: 4,
        code: "AiSelection",
      }, {
        src: require('@/assets/images/mt/cell4.png'),
        txt: "智慧调剂",
        num: 5,
        code: "SmartAdjust",
      }, {
        src: require('@/assets/images/mt/cell8.png'),
        txt: "报录数据",
        num: 8,
        code: "Admit",
      }, {
        src: require('@/assets/images/mt/cell7.png'),
        txt: "调剂公告",
        num: 7,
        code: "Adjust",
      }, {
        src: require('@/assets/images/mt/cell9.png'),
        txt: "视频网课",
        num: 9,
        code: "Course",
      }, {
        src: require('@/assets/images/mt/cell11.png'),
        txt: "估分排名",
        num: 11,
        code: "GradeRank",
      }, {
        src: require('@/assets/images/mt/cell6.png'),
        txt: "国家线",
        num: 6,
        code: "NationalLine",
      }, {
        src: require('@/assets/images/mt/cell10.png'),
        txt: "加盟代理",
        num: 10,
        code: "League",
      }],
      schools: [{
        name: "东北林业大学",
        code: "10225",
        province: "黑龙江",
        teachtype: "农林类",
        characteristic: "高等院校 农林类 211 双一流",
        src: require('@/assets/images/mt/376.jpg'),
      }, {
        name: "河南科技大学",
        code: "10464",
        province: "河南",
        teachtype: "综合类",
        characteristic: "",
        src: require('@/assets/images/mt/496.jpg'),
      }, {
        name: "上海交通大学",
        code: "10248",
        province: "上海",
        teachtype: "综合类",
        characteristic: "",
        src: require('@/assets/images/mt/267.jpg'),
      }, {
        name: "河南师范大学",
        code: "10476",
        province: "河南",
        teachtype: "师范类",
        characteristic: "",
        src: require('@/assets/images/mt/124.jpg'),
      }, {
        name: "北京师范大学",
        code: "10027",
        province: "北京",
        teachtype: "师范类",
        characteristic: "",
        src: require('@/assets/images/mt/174.jpg'),
      }],
    };
  },
  computed: {
    distanceDays() {
      var a1 = new Date();
      var a2 = Date.parse(new Date('2025-12-20'));
      var day = parseInt((a2 - a1) / (1000 * 60 * 60 * 24));//核心：时间戳相减，然后除以天数
      return day;
    },
    menuImages() {
      var imgs = [];
      for (let i = 0; i < this.images.length; i++) {
        if (this.menuPermissions && this.menuPermissions.includes(this.images[i].code)) {
          imgs.push(this.images[i]);
        }
      }
      return imgs;
    },
    proxySwipeImage() {
      return function (code) {
        return Config.proxy.image + `?ProxyCode=${this.$store.state.proxyCode}&ImageIdentity=${code}`;
      }
    }
  },
  methods: {
    //获取单元格轮播数量
    getCellSwipeCount() {
      return Math.ceil(this.menuImages.length / 8);
    },
    //获取单元格列数量
    getCellColumnCount() {
      return 4;
    },
    //获取单元格每页显示图标
    getCellPageImages(index) {
      let size = 8;
      let imgs = [];
      for (let i = 0; i < this.menuImages.length; i++) {
        if (i >= (index - 1) * size && i < index * size) {
          imgs.push(this.menuImages[i]);
        }
      }
      return imgs;
    },
    //获取代理商信息
    getProxyInfo() {
      var model = {
        ProxyCode: this.$store.state.proxyCode,
      };
      return this.$axios
        .post(Config.proxy.menuPermissionList, model)
        .then((res) => {
          this.menuPermissions = res.data.Data.MenuPermissions;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //点击搜索
    onSearch() {
      let params = {
        proxyCode: this.$store.state.proxyCode
      };
      let query = {
        type: this.type ? this.type : undefined,
        keyword: this.keyword ? this.keyword : undefined,
      };
      if (this.type == '1') {
        this.$router.push({ name: "Document", params: params, query: query });
      } else if (this.type == '2') {
        this.$router.push({ name: "School", params: params, query: query });
      } else if (this.type == '3') {
        this.$router.push({ name: "Major", params: params, query: query });
      } else if (this.type == '4') {
        this.$router.push({ name: "Article", params: params, query: query });
      }
    },
    //点击后台
    onSystem() {
      window.location.href = "https://www.handebook.com/proxy"
    },
    //点击单元格
    onCellClick(item) {
      this.$router.push({ name: item.code, params: { proxyCode: this.$store.state.proxyCode } });
    },
    //点击大学项
    onItemClick(item) {
      this.$router.push({
        name: "School1",
        params: {
          proxyCode: this.$store.state.proxyCode,
          universityCode: item.code
        }
      });
    },
  },
  mounted() {
    this.getProxyInfo();
  }
};
</script>
<style lang="less" scoped>
.home-box {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}

.home-swipe {
  border-radius: 10px;
  max-height: 150px;

  .swipe-img {
    display: unset;
    width: 100%;
    height: 100%;
  }
}

.cell-swipe {
  background-color: white;
  border-radius: 10px;
  margin: 10px 0;
}

.cell-grid {
  border-radius: 10px;

  .cell-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .cell-img {
      height: 35px;
    }

    .cell-txt {
      font-size: 14px;
      margin-top: 5px;
    }
  }
}

.school-hot {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 5px;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 5px;

  .hot-img {
    width: 20px;
    height: 20px;
  }

  .hot-title {
    font-size: 18px;
    font-weight: bold;
    margin-left: 10px;
  }
}

.school-item {
  margin-bottom: 10px;
}

.calendar-box {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 10px;

  .calendar-line1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;

    .line1-rect {
      width: 2px;
      height: 15px;
      margin-right: 5px;
    }

    .line1-txt1 {
      font-size: 18px;
    }

    .line1-txt2 {
      color: gray;
      font-size: 13px;
      margin-left: 10px;
    }
  }

  .calendar-line2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    .line2-txt {
      color: white;
      font-size: 16px;
      padding: 0px 20px;
      text-align: center;
      background-color: #fe6601;
      border-radius: 20px;
    }
  }

  .calendar-line3 {
    width: 100%;
    overflow-x: auto;
    margin-bottom: 10px;

    .line3-img {
      height: 50px;
    }
  }

  .calendar-line3::-webkit-scrollbar {
    display: none;
  }
}

::v-deep .van-grid-item__content {
  background: transparent;
  padding: 10px;
}

::v-deep .van-swipe__indicator {
  background-color: white;
  opacity: 1;
}

::v-deep .van-swipe__indicators {
  background-color: rgba(190, 190, 190, 0.3);
  padding: 2px 5px;
  border-radius: 10px;
  position: absolute;
  bottom: 6px;
}
</style>